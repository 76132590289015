import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"

import { trackEvent } from "../../helpers/tracker"

const FieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 241px;
  height: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`

const FieldTitle = styled.div`
  padding: 10px 17px;
  background-color: #ededed;
  font-family: "Open Sans";
  font-style: normal;
  line-height: 22px;
  font-weight: 600;
  color: #363f41;
  height: 43px;
  width: 100%;
`

const CostField = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.75em;
  padding: 14px 14px 14px 17px;
  min-height: 63px;
  width: 100%;
`

const CostFieldNote = styled.span`
  font-family: "Open Sans";
  font-size: 10px;
  line-height: 14px;
  color: #7b7b7b;
`

const BenefitField = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.75em;
  padding: 14px 14px 14px 17px;
  min-height: 81px;
  width: 100%;
`

const BenefitFieldNote = styled.span`
  font-family: "Open Sans";
  font-size: 10px;
  line-height: 14px;
  color: #363f41;
`

const OperationField = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.75em;
  padding: 14px 14px 14px 17px;
  min-height: 56px;
  width: 100%;
`

const OperationLink = styled.a`
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`

const EmptyField = styled.div`
  height: 175px;
  width: 100%;
`

const costFields = [
  "Apertura cuenta",
  <span>
    Comisión depósito/retiro* <br />
    <CostFieldNote>*Tarjeta de Crédito podría aplicar cargo 1%</CostFieldNote>
  </span>,
  <span>
    Mantención* <br />
    <CostFieldNote>*Plan Start costo $0 con operación abierta</CostFieldNote>
  </span>,
]

const benefitFields = [
  <span>
    <strong>Newsletter</strong> <br />
    <BenefitFieldNote>
      Columna de opinión + Plan de Trading semanal + Oportunidades de trading
      diarias
    </BenefitFieldNote>
  </span>,
  <span>
    Acceso exclusivo a todos nuestros <strong>seminarios y webinars</strong>
  </span>,
  <span>
    <strong>Instrumentos</strong> para operar
  </span>,
  <strong>Apalancamiento</strong>,
  <span>
    <strong>Curso de Análisis Técnico</strong> <br />
    Básico + Avanzado
  </span>,
  <span>
    Atención <strong>equipo de Traders</strong>
  </span>,
  <span>
    <strong>Descuento</strong> en spread
  </span>,
  <span>
    <strong>Invitación</strong> a eventos especiales
  </span>,
]

const operationFields = (
  queryString: string,
  onClickOperationalAspects: () => void
) => [
  <span>
    <strong>Lotes mínimos</strong> <br />
    <OperationLink
      href={`https://www.capitaria.com/aspectos-operativos/${queryString}`}
      target="_blank"
      onClick={onClickOperationalAspects}
    >
      *ver Aspectos Operativos
    </OperationLink>
  </span>,
  <span>
    <strong>Lotes máximos</strong> <br />
    <OperationLink
      href={`https://www.capitaria.com/aspectos-operativos/${queryString}`}
      target="_blank"
      onClick={onClickOperationalAspects}
    >
      *ver Aspectos Operativos
    </OperationLink>
  </span>,
]

const AccountTypeFields = () => {
  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  const onClickOperationalAspects = () => {
    const eventName = `tipos-de-cuentas:operational-aspects`

    trackEvent(eventName, {
      mc_origen: mcOrigen,
    })
  }

  return (
    <FieldsWrapper>
      <EmptyField />

      <FieldTitle>Costos</FieldTitle>
      {costFields.map((f, idx) => (
        <CostField key={`cfield-${idx}`}>{f}</CostField>
      ))}

      <FieldTitle>Beneficios</FieldTitle>
      {benefitFields.map((f, idx) => (
        <BenefitField key={`bfield-${idx}`}>{f}</BenefitField>
      ))}

      <FieldTitle>Montos operación</FieldTitle>
      {operationFields(queryString, onClickOperationalAspects).map((f, idx) => (
        <OperationField key={`ofield-${idx}`}>{f}</OperationField>
      ))}
    </FieldsWrapper>
  )
}

export default AccountTypeFields
