import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import AccountsTypesPage from "../components/accounts-types-page/accounts-types-page"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const AccountsTypes = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Tipos de cuentas" />
      <AccountsTypesPage />
    </Layout>
  </QueryStringProvider>
)

export default AccountsTypes
