import React from "react"
import styled from "@emotion/styled"

import AccountType from "./account-type"
import PlanFields from "./account-type-fields"
import PageLayout from "../ui/page-layout"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Description = styled.p`
  font-family: Nunito;
  font-size: 18px;
  line-height: 120%;
  color: #1f2425;
  text-align: center;
  padding: 3rem 0 3rem;
  max-width: 314px;
  @media (min-width: 1024px) {
    max-width: 533px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    max-width: 533px;
  }
`

const AccountsTypesWrapper = styled.section`
  display: flex;
  margin-bottom: 6rem;
  margin-left: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    margin-left: -200px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0;
  }
`

const accountsTypes = [
  {
    recommended: false,
    accountTypeName: "START",
    beginningPrice: "400 USD / CLP $300.000",
    openingCost: "$0",
    depositCost: "$0",
    maintenanceCost: "3 USD / 2.000 CLP",
    newsLetter: true,
    webinars: true,
    instruments: "16",
    leverage: "0, x2, x4",
    tradingCourse: false,
    supportTeam: false,
    exclusiveDiscount: false,
    specialEvents: false,
    minVolumen: "0,01",
    maxVolumen: "1",
  },
  {
    recommended: true,
    accountTypeName: "PRO",
    beginningPrice: "6.000 USD / CLP $5.000.000",
    openingCost: "$0",
    depositCost: "$0",
    maintenanceCost: "$0",
    newsLetter: true,
    webinars: true,
    instruments: "+450",
    leverage: "hasta x100",
    tradingCourse: true,
    supportTeam: true,
    exclusiveDiscount: false,
    specialEvents: false,
    minVolumen: "0,1",
    maxVolumen: "hasta 60*",
  },
  {
    recommended: false,
    accountTypeName: "PREMIUM",
    beginningPrice: "30.000 USD / CLP $25.000.000",
    openingCost: "$0",
    depositCost: "$0",
    maintenanceCost: "$0",
    newsLetter: true,
    webinars: true,
    instruments: "+450",
    leverage: "hasta x100",
    tradingCourse: true,
    supportTeam: true,
    exclusiveDiscount: true,
    specialEvents: true,
    minVolumen: "desde 0,5*",
    maxVolumen: "hasta 60*",
  },
]

const AccountTypePage = () => (
  <PageLayout title="Tipos de cuentas">
    <Container>
      <Description>
        Porque sabemos que cada trader tiene niveles de experiencia y objetivos
        diferentes, hemos creado 3 tipos de cuentas diferentes.
      </Description>

      <AccountsTypesWrapper>
        <PlanFields />

        {accountsTypes.map((at, idx) => (
          <AccountType key={`account-type-${idx}`} {...at} />
        ))}
      </AccountsTypesWrapper>
    </Container>
  </PageLayout>
)

export default AccountTypePage
